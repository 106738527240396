import React from 'react'
import PortailHeader from '../Layouts/PortailHeader'
import Footer from '../Layouts/Footer'
import SidebarSeller from './sideBar'

const ProfileSeller = () => {
  return (
    <div>
        <PortailHeader></PortailHeader>
        <SidebarSeller></SidebarSeller>
        //store style

<Footer></Footer>
    </div>
  )
}

export default ProfileSeller
import React from 'react'

const ProcessedProd = () => {
  return (
    <div>
   You don't have any Orders Yet!
    </div>
  )
}

export default ProcessedProd